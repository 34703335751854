<template>
  <div class="castinglist-style">
    <div class="list">
      <el-card class="box-card" v-for="(t, i) in 10" :key="i">
        <div class="between-center">
          <img src="@/assets/img/s.png" alt="" class="item-i1" />
          <div class="item-t1">Ture NFT#1258</div>
          <div class="item-t2" @click="goAhead('/nftdetail')">{{$t('text.t77')}}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.castinglist-style {
  .list {
    padding: 21px 0 0;
    .box-card {
      width: 300px;
      border-radius: 10px;
      margin: 0 auto 20px;
      .item-i1 {
        width: 33px;
        height: 33px;
        margin-right: 10px;
      }
      .item-t1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        flex: 1;
      }
      .item-t2 {
        font-size: 12px;
        font-weight: 500;
        color: #1359b0;
        margin-left: 10px;
      }
    }
  }
}
</style>